import './app.css'
import Router from 'preact-router';
import PayslipAuth from "./components/PayslipAuth.jsx";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {useEffect} from 'preact/hooks';
import ErrorBoundary from "./components/ErrorBoundary.jsx";

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    releaseStage: import.meta.env.VITE_EWAGE_ENV,
    enabledReleaseStages: ['production', 'staging'],
    plugins: [new BugsnagPluginReact()]
})

export function App() {
    useEffect(() => {
        Bugsnag.leaveBreadcrumb('App mounted.')
    }, [])

    return (
        <ErrorBoundary>
            <div className="mx-auto px-6 pt-6">
                <Router>
                    <PayslipAuth path="/ps/:payslipId?"/>
                </Router>
            </div>
        </ErrorBoundary>
    )
}
