import Card from "./Card.jsx";
import {toCurrency, toTitleCase} from "../utils/helpers.js";
import {useEffect, useState} from "preact/hooks";
import {LeaveManagement} from "./LeaveManagement.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import Bugsnag from "@bugsnag/js";

const Payslip = ({payslip, employee, payslipId}) => {
    const [blobCache, setBlobCache] = useState(null);
    const [filenameCache, setFilenameCache] = useState(null);
    const [error, setError] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isSharing, setIsSharing] = useState(false);

    const incomeFields = Object.entries(payslip.incomeFieldValues).map(([key, value]) => {
        return {[key]: value};
    });
    const deductionsFields = Object.entries(payslip.deductionFieldValues).map(([key, value]) => ({[key]: value}));

    useEffect(() => {
        Bugsnag.leaveBreadcrumb('Payslip mounted.')
    }, []);

    const getPayslipBlob = async () => {
        if (blobCache && filenameCache) {
            return {blob: blobCache, filename: filenameCache};
        }
        const response = await fetch(`${import.meta.env.VITE_EWAGE_API_URL}/ewage/getPayslipPdf/${payslipId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password: employee.idNumber
            })
        });
        // Handle PDF response
        if (response.ok) {
            // Get filename from response headers and remove quotes
            let filename = response.headers
                .get('Content-Disposition')
                .split('filename=')[1]

            // Remove quotes from filename
            filename = filename.substring(1, filename.length - 1);
            setFilenameCache(filename);

            const blob = await response.blob();
            await setBlobCache(blob);
            return {blob, filename};
        } else {
            console.error(response);
            setError('Unable to fetch payslip.');
        }
    };
    const sharePayslipPdf = async () => {
        setIsSharing(true);
        try {
            const {blob, filename} = await getPayslipBlob();
            const file = new File([blob], filename, {type: blob.type});
            await navigator.share({
                title: 'Share your payslip',
                files: [file]
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsSharing(false);
        }
    }
    const downloadPayslipPdf = async () => {
        setIsDownloading(true);
        const {blob, filename} = await getPayslipBlob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        setIsDownloading(false);
    }

    return (payslip && employee) && (
        <>
            <div className="flex flex-col mt-2 sm:mt-10 max-w-2xl mx-auto">
                {/* Header */}
                <div className="flex flex-row mb-3 pl-2 items-center">
                    <div className="flex flex-col">
                        <h1 className="text-sm uppercase font-light text-gray-500">Payslip Summary</h1>
                        <h2 className="text-2xl font-light text-gray-900">{toTitleCase(employee.name)}</h2>
                        <h2 className="text-md font-light text-gray-600">{toTitleCase(employee.organization.name)}</h2>
                        {employee?.taxNumber && (
                            <h2 className="text-xs font-light text-gray-400">Tax Number: {employee.taxNumber}</h2>
                        )}
                    </div>
                </div>


                <Card className="mb-5">
                    <div className="px-5 py-4">
                        <div className="flex items-end pb-1">
                            <h1 className="text-teal-700 font-medium">Income</h1>
                        </div>
                        <div>
                            {incomeFields.map((field) => {
                                const name = Object.keys(field)[0];
                                const value = field[name].value;
                                const hours = field[name]?.hours;
                                const hourlyRate = field[name]?.hourly_rate;
                                return value && (
                                    <div>
                                        <div className="flex justify-between py-1 text-sm text-gray-600">
                                            <p className="truncate w-3/4 pr-2">{name}</p>
                                            <p className="flex-shrink-0 w1/4">{toCurrency(value)}</p>
                                        </div>
                                        <div className="flex text-xs mt-1 text-gray-500">
                                            {hours && <p className="pr-1">{hours} hours</p>}
                                            {hourlyRate && <p className="">@ {toCurrency(hourlyRate)}</p>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex justify-between items-center bg-gray-100 py-2.5 px-4 text-xs text-gray-700">
                        <span className="font-bold">Total Earnings</span>
                        <span className="font-bold">{toCurrency(payslip.fields['Total Income']['value'])}</span>
                    </div>
                </Card>
                <Card>
                    <div className="px-5 py-4">
                        <div className="">
                            <p className="pb-1 text-teal-700 font-medium">Deductions</p>
                        </div>
                        <div>
                            {deductionsFields.map((field) => {
                                const name = Object.keys(field)[0];
                                const value = field[name].value;
                                return value && (
                                    <div className="flex justify-between py-1 text-sm text-gray-600">
                                        <p className="truncate w-3/4 pr-2">{name}</p>
                                        <p className="flex-shrink-0 w1/4">{toCurrency(value)}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex justify-between items-center bg-gray-100 py-2.5 px-4 text-xs text-gray-700">
                        <span className="font-bold">Total Deductions</span>
                        <span className="font-bold">{toCurrency(payslip.fields['Total Deductions']['value'])}</span>
                    </div>
                </Card>
                <div className="flex flex-col items-center print:items-end my-5">
                    <p className="text-sm text-teal-800">Take Home Pay</p>
                    <p className="text-2xl font-medium">{toCurrency(payslip.fields['Net Pay']['value'])}</p>
                </div>


                {/* Company Contributions */}
                {payslip.companyContributionFieldValues.length > 0 && (
                    <Card className="mb-5">
                        <div className="px-5 py-4">
                            <div className="">
                                <p className="pb-1 text-teal-700 font-medium">Company Contributions</p>
                            </div>
                            <div>
                                {payslip.companyContributionFieldValues.map((field) => {
                                    return (field.value && Math.abs(field.value) > 0) && (
                                        <div className="flex justify-between py-1 text-sm text-gray-600">
                                            <p className="truncate w-3/4 pr-2">{field.name}</p>
                                            <p className="flex-shrink-0 w1/4">{toCurrency(field.value)}</p>
                                        </div>)
                                })}
                            </div>
                        </div>

                        <div
                            className="flex justify-between items-center bg-gray-100 py-2.5 px-4 text-xs text-gray-700">
                            <span className="font-bold">Total Company Contributions</span>
                            <span className="font-bold">{toCurrency(payslip.totalCompanyContributions)}</span>
                        </div>
                    </Card>
                )}

                {/* If any notes have a value, show the notes section */}
                {payslip.noteFields.filter(field => field.value).length > 0 && (
                    <Card>
                        <div className="px-5 py-4">
                            <div className="flex items-end pb-1">
                                <h1 className="text-teal-700 font-medium">Notes</h1>
                            </div>
                            <div>
                                {payslip.noteFields.filter(field => {
                                    const visibility = field?.visibility;
                                    if (!visibility) return false;
                                    return field.visibility.includes('summary')
                                }).map(field => {
                                    if (field.value) {
                                        return (
                                            <div className="py-1">
                                                <div className="flex text-sm text-gray-600">
                                                    <p className="flex-1">{field.name}</p>
                                                    {field.type === 'currency' ? (
                                                        <p className="text-end">{toCurrency(field.value)}</p>
                                                    ) : (
                                                        <p className="text-end">{field.value}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </Card>
                )}

                <div className="sm:mx-auto">
                    <div className="rounded-md shadow-sm my-5 sm:w-96">
                        {navigator.canShare ? (
                            <>
                                <button
                                    onClick={downloadPayslipPdf}
                                    type="button"
                                    disabled={isSharing || isDownloading}
                                    className="relative w-1/2 items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500 disabled:bg-gray-50 disabled:text-gray-400"
                                >
                                    {isDownloading ? (
                                        <div className="flex justify-center">
                                            <span>Loading...</span>
                                            <LoadingSpinner spinnerStyles="h-5 w-5" containerStyles="ml-2"
                                                            color="black"/>
                                        </div>
                                    ) : (
                                        <span>Download PDF</span>
                                    )}
                                </button>
                                <button
                                    onClick={sharePayslipPdf}
                                    type="button"
                                    disabled={isSharing || isDownloading}
                                    className="relative -ml-px w-1/2 items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500 disabled:bg-gray-50 disabled:text-gray-400"
                                >
                                    {isSharing ? (
                                        <div className="flex justify-center">
                                            <span>Loading...</span>
                                            <LoadingSpinner spinnerStyles="h-5 w-5" containerStyles="ml-2"
                                                            color="black"/>
                                        </div>
                                    ) : (
                                        <span>Share PDF</span>
                                    )}
                                </button>
                            </>
                        ) : (
                            <button
                                onClick={downloadPayslipPdf}
                                type="button"
                                disabled={isDownloading}
                                className="flex flex-row justify-center text-center w-full items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:bg-gray-50 disabled:text-gray-400"
                            >
                                {isDownloading ? (
                                    <>
                                        <span>Loading...</span>
                                        <LoadingSpinner spinnerStyles="h-5 w-5" containerStyles="ml-2" color="black"/>
                                    </>
                                ) : (
                                    <span>Download PDF</span>
                                )}
                            </button>
                        )}
                    </div>

                    {error && (
                        <div className="mt-2 text-sm text-center text-red-500">
                            <p>{error}</p>
                        </div>
                    )}
                </div>

                {employee?.leaveDaysAvailable && (
                    <LeaveManagement employee={employee}/>
                )}

                <div className="w-full p-2 text-sm text-gray-400">
                    <p className="text-center">Powered by <a href="https://www.ewage.co.za">eWage</a></p>
                </div>
            </div>
        </>
    )
}
export default Payslip;