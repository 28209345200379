import {Component} from 'preact'
import Card from "./Card.jsx";
import Bugsnag from "@bugsnag/js";

class ErrorBoundary extends Component {
    state = {error: null}

    static getDerivedStateFromError(error) {
        return {error: error.message}
    }

    componentDidCatch(error) {
        console.error(error)
        Bugsnag.notify(error)
        this.setState({error: error.message})
    }

    render() {
        if (this.state.error) {
            return (
                <div className="flex justify-center items-center h-screen mx-10">
                    <Card className="p-6">
                        <h1 className="text-xl font-bold text-gray-500">
                        We're sorry, something went wrong.</h1>
                        <p
                            className="text-sm text-gray-500 mt-5"
                        >If you're seeing this, the error has been reported to us and we are looking into it right
                            away.</p>
                        <p
                            className="text-sm text-gray-500 mt-5"
                        >Please contact support at <a
                            href="mailto:info@ewage.co.za"
                            className="text-teal-500 hover:text-teal-600">
                            info@ewage.co.za</a> if you need assistance.</p>
                        <p
                            className="text-sm text-gray-500 mt-5"
                        >
                            - The eWage Team
                        </p>
                    </Card>
                </div>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary;