import LockClosed from "./icons/LockClosed.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import {useEffect} from "preact/hooks";
import Bugsnag from "@bugsnag/js";

export const PayslipAuthView = ({onSubmit, onChange, validationError, isLoading}) => {

    useEffect(() => {
        Bugsnag.leaveBreadcrumb('PayslipAuthView mounted.')
    }, [])

    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div className="flex flex-col items-center">
                        <h1 className="text-xl text-gray-800">eWage</h1>
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-800">
                            Secure login
                        </h2>
                    </div>
                    <form
                        onSubmit={onSubmit}
                        className="mt-8 space-y-6"
                    >
                        <input type="hidden" name="remember" defaultValue="true"/>
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    South African ID Number
                                </label>
                                <input
                                    onChange={onChange}
                                    id="idNumber"
                                    name="idNumber"
                                    type="text"
                                    autoComplete="idNumber"
                                    required
                                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                                    placeholder="National ID or Passport Number"
                                />
                                {validationError && (
                                    <p className="text-red-500 text-sm text-center mt-4">
                                        {validationError}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <button
                                id="view-payslip"
                                name="view-payslip"
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                            >
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            {isLoading ? (
                                <LoadingSpinner spinnerStyles="h-5 w-5" color="white"/>
                            ) : (
                                <LockClosed className="h-5 w-5 text-teal-500 group-hover:text-teal-400"
                                            aria-hidden="true"/>
                            )}
                        </span>

                                View Payslip
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Fixed Footer */}
            <div className="fixed bottom-2 left-0 w-full text-sm text-gray-400">
                <p className="text-center">Powered by eWage</p>
            </div>
        </>
    )
};