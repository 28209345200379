import {useEffect, useState} from "preact/hooks";
import Payslip from "./Payslip.jsx";
import {PayslipAuthView} from "./PayslipAuthView.jsx";
import Bugsnag from "@bugsnag/js";

const PayslipAuth = ({payslipId}) => {
    const [payslip, setPayslip] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        Bugsnag.leaveBreadcrumb('PayslipAuth component mounted.')
    }, [])

    const fetchPayslip = async (idNumber) => {
        Bugsnag.leaveBreadcrumb('Fetching payslip.')
        await setIsLoading(true);
        const response = await fetch(`${import.meta.env.VITE_EWAGE_API_URL}/ewage/getPayslip/${payslipId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password: idNumber
            })
        });
        const data = await response.json();
        if (data.error) {
            setValidationError(data.message);
        } else {
            setPayslip(data.payslip);
            setEmployee(data.employee);
        }
        await setIsLoading(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        Bugsnag.leaveBreadcrumb('Submitting payslip auth form.')
        const idNumber = e.target.idNumber.value;
        await fetchPayslip(idNumber);
    };

    return (
        <>
            {!payslip ? (
                <PayslipAuthView
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                    onChange={() => setValidationError(null)}
                    validationError={validationError}/>
            ) : (
                <Payslip payslip={payslip} employee={employee} payslipId={payslipId}/>
            )}
        </>
    )
}
export default PayslipAuth;