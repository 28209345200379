import Card from "./Card.jsx";
import ChevronDown from "./icons/ChevronDown.jsx";
import {useState} from "preact/hooks";
import ChevronUp from "./icons/ChevronUp.jsx";

export function LeaveManagement({employee}) {
    const [showLeaveManagement, setShowLeaveManagement] = useState(false);

    function toggleLeaveManagement() {
        setShowLeaveManagement((showLeaveManagement) => !showLeaveManagement);
    }

    if (employee.leaveDaysAvailable == null) {
        return null;
    }

    return (
        <>
            <div className="my-5">
                <p className="text-lg text-teal-800">Leave Management</p>
                <p className="text-sm font-light text-gray-700">{employee.leaveDaysAvailable} Leave
                    day{employee.leaveDaysAvailable > 1 && "s"} remaining</p>
            </div>
            <Card className="mb-5">
                <div className="px-5">
                    <form>
                        <div
                            className="flex justify-between items-center"
                            onClick={toggleLeaveManagement}
                        >
                            <h3 className="py-3 text-gray-700 text-sm">Apply for leave</h3>
                            {showLeaveManagement ? (
                                <ChevronUp className="w-5 h-5 text-gray-500"/>
                            ) : (
                                <ChevronDown className="w-5 h-5 text-gray-500"/>
                            )}
                        </div>
                        {showLeaveManagement && (
                            <div className="py-3">
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Start date
                                    </label>
                                    <div
                                        className="mt-1 block w-full rounded-md border-solid border-gray-400 text-sm text-gray-400 shadow-sm focus:border-teal-500">
                                        <input
                                            type="date"
                                            name="start-date"
                                            id="start-date"
                                            className=" outline-0 w-full"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        End date
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="date"
                                            name="end-date"
                                            id="end-date"
                                            className="mt-1 block w-full rounded-md border-solid border-gray-300 text-sm text-gray-400 shadow-sm focus:border-teal-500"
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="w-full block items-center rounded-md border border-gray-300 bg-white py-2 mt-6 mb-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                                >
                                    Submit
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </Card>
        </>
    );
}