const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
}

const toCurrency = (amount) => {
    // First convert the amount to a number
    amount = Number(amount);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ZAR',  // Set currency to a valid code
        minimumFractionDigits: 2,
    });
    const formatted =  formatter.format(amount);
    // Replace the currency symbol with the word "Rand"
    return formatted.replace('ZAR', 'R');
}

// Export the validation functions
export {
    toTitleCase,
    toCurrency
};